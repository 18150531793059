import React from "react";
import { Helmet } from "react-helmet";
import "./style.sass";

import Gado from "./assets/gado.jpg";
import Analista from "./assets/analista.jpg";
import Icon_Ambiente from "./assets/ambiente.svg";
import Icon_Premio from "./assets/premio.svg";
import Icon_Selo from "./assets/selo.svg";

const Sustentabilidade = () => {
  const info = [
    {
      icone: Icon_Ambiente,
      descricao:
        "Atuar de forma social e ambientalmente responsável também significa adotar critérios de compra responsável. Este princípio norteia a nossa gestão de sustentabilidade, garantindo a oferta de produtos confiáveis.",
    },
    {
      icone: Icon_Premio,
      descricao:
        "No Maranhão, em nosso setor, somos pioneiros em adotar medidas para monitoramento socioambiental dos fornecedores de gado e fazendas de origem, com a utilização de tecnologia de georreferenciamento.",
    },
    {
      icone: Icon_Selo,
      descricao:
        "O SMGeo da empresa Niceplanet é a ferramenta de georreferenciamento em uso para garantir o enquadramento socioambiental de nossos fornecedores a um abrangente protocolo de exigências.",
    },
  ];

  const timeline = [
    {
      ano: "2021",
      descricao:
        "Implementação da rastreabilidade socioambiental na originação do gado para abate.",
    },
    {
      ano: "2022",
      descricao:
        "Consolidação da rastreabilidade para 100% dos fornecedores diretos de gado (Nível 1), 1.377 pecuaristas e 1.628 propriedades monitoradas.",
    },
    {
      ano: "2023",
      descricao:
        "Implementação da rastreabilidade socioambiental para a atividade pecuária do grupo na aquisição de bezerros e garrotes, para fornecedores diretos (Nível 1).",
    },
    {
      ano: "2024",
      descricao:
        "Busca e fomento à tecnologias para rastreabilidade socioambiental para níveis mais baixos da cadeia bovina.",
    },
    {
      ano: "2025",
      descricao:
        "Implantação da rastreabilidade socioambiental para fornecedores indiretos (nível2), na compra de gado para abate.",
    },
  ];

  return (
    <main className="sustentabilidade">
      <Helmet>
        <title>Sustentabilidade</title>
      </Helmet>
      <h2 className="sustentabilidade__title">Controle de origem</h2>
      <h3 className="sustentabilidade__subtitle">
        O monitoramento socioambiental da origem do gado.
      </h3>
      <div className="sustentabilidade__container">
        <div className="sustentabilidade__content">
          <div className="sustentabilidade__info">
            <section className="sustentabilidade__sobre sobre">
              <ul className="sobre__list">
                {info.map((item, index) => (
                  <li key={index} className="sobre__item">
                    <img src={item.icone} alt="Ícone de meio ambiente" />
                    <p className="sobre__description">{item.descricao}</p>
                  </li>
                ))}
              </ul>
            </section>
            <section className="sustentabilidade__compromissos compromissos">
              <h3 className="compromissos__title title">Compromisso</h3>
              <div className="compromissos__info">
                <p className="compromissos__description">
                  A FRIBAL, tem por princípios contribuir, incentivar e fomentar
                  o desenvolvimento socioambiental sustentável das regiões onde
                  estão localizadas suas operações de frigorífico e pecuária.
                  Desta forma, torna pública a sua atuação e seus compromissos
                  na compra de gado.
                </p>
                <p className="compromissos__description">
                  Nossas ações têm como objetivo o combate ao desmatamento,
                  invasões e explorações ilegais em áreas do Bioma Amazônico e
                  do Cerrado, bem como coibir práticas análogas às de trabalho
                  escravo. Em linha com nosso contínuo esforço, ampliaremos a
                  aplicação da rastreabilidade socioambiental para outros níveis
                  da cadeia bovina, conforme linha do tempo abaixo.
                </p>
              </div>
            </section>
          </div>
          <div className="sustentabilidade__galeria galeria">
            <ul className="galeria__list">
              <li className="galeria__item">
                <img
                  className="galeria__image"
                  src={Gado}
                  alt="Imagem de um gado"
                />
              </li>
              <li className="galeria__item">
                <img
                  className="galeria__image"
                  src={Analista}
                  alt="Imagem de uma analista"
                />
              </li>
            </ul>
          </div>
        </div>
        <section className="sustentabilidade__timeline timeline">
          <h3 className="timeline__title title">Linha do tempo</h3>
          <ul className="timeline__list">
            {timeline.map((ano) => (
              <li
                key={ano.ano}
                className={`timeline__item ${
                  ano.ano === "2025" ? "timeline__item--last" : ""
                }`}
              >
                <h3 className="timeline__ano">{ano.ano}</h3>
                <p className="timeline__description">{ano.descricao}</p>
              </li>
            ))}
          </ul>
        </section>
      </div>
    </main>
  );
};

export default Sustentabilidade;
